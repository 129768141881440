@import "utils";
@import "shared/page";
@import "@angular/material/prebuilt-themes/indigo-pink.css";

.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate{
  background: $mongoose;
}

.mat-select-placeholder.mat-select-min-line , .mat-option-text , .mat-select-min-line{
  font-family: $font-family-base;
  color: $cloudy;
  background-color: transparent;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
}

.request-title {
  font-weight: $font-weight-bold;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 12px;
}

.request-sup-title {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 32px;
  padding-top: 25px;
}

.sup-title {
  font-size: 32px;
  letter-spacing: 0;
  line-height: 12px;
  margin-top: 100px;
}

.sup-description {
  font-size: 18px;
  letter-spacing: 0;
  font-weight: 400;
  margin-top: 50px;
}

.form-control {
  height: calc(2.3em + 0.75rem + 2px);
}

img,
i {
  margin-left: 5px;
}

.modal {
  .modal-footer {
    justify-content: flex-start
  }
}

body {
  background-color: #fff;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: $text-tertiary;
}


.published-card {
    display: flex;
    margin-bottom: 80px;
    &__date-type {
        color: white;
        text-align: center;
        font-weight: $font-weight-bold;
        margin-left: .5rem;
        position: absolute;
    }
    &__date,
    &__type {
        padding: 15px 10px;
        height: 89px;
        width: 89px;
        font-size: 12px;
    }
    &__date {
        background-color: $cloudy;
    }
    &__type {
        background-color: $mongoose;
        max-width: 140px;
    }
    &__data {
        border: 1px solid rgba(151, 151, 151, 0.15);
        padding: 2rem;
        flex: 1;
        p {
            margin-bottom: .5rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__id {
        font-size: 16px;
        font-weight: $font-weight-bold;
        color: $mongoose;
    }
    &__status,
    &__phone-number {
        font-weight: $font-weight-bold;
    }

    &__time {
      color: #5A9346;
    }

    p {
      font-size: 16px;
      font-weight: 500;
    }

    .btn {
      height: 55px;
      width: 185px;
      display: block;
      margin: 20px auto 30px;
    }

    .download-btn {
      text-decoration: none;
      padding: 0;
      color: #47763B;
      margin: 0;
      height: 20px;
      width: auto;
      text-align: right;

      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
}


.lands-modal.modal{
.modal-header{
  padding: 1rem 4rem 1rem 2rem;
  align-items: center;
}
.modal-header .close {
  font-size: 45px;
  font-weight: 400;
}

@media (min-width: 992px){
.modal-lg, .modal-xl {
    max-width: 600px;
}
}
}

.modal-change-eng-office{
  .modal-content {
    padding: .5rem;
    .modal-header{
      padding: 0rem .5rem .5rem .5rem;
      border-bottom: 1px solid #eee;
    }
    .modal-body{
      text-align: right;
      padding: 1rem 1rem 0;
    }
    .modal-footer{
      padding: 0 .5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .modal-change-eng-office{
  .modal-content {
    padding: 1.5rem;
    .modal-header{
      padding: .5rem 1.5rem 1.5rem 1.5rem;
    }
    .modal-body{
      padding: 2rem 2rem 0;
    }
    .modal-footer{
      padding: 0 1.5rem;
    }
  }
}
}