@import "../utils";

.page {
  &__content {
    margin-top: 30px;
  }

  &__image {
    margin-bottom: 3rem;
  }

  &__title {
    font-size: 32px;
    font-weight: $font-weight-bold;
  }

  &__description {
    font-size: 18px;
  }

  &__small {
    font-size: 14px;
    font-weight: $font-weight-bold;
    margin-top: 40px;
  }

  &__inputs {
    margin: 3.5rem 0;
    width: (400%/7);
  }
}




.modal{
  &-content{
      text-align: center;
  }
  &-header{
      flex-direction: row;
      align-items: center;
      padding: 40px 40px 0 40px;
      
  }
  &-footer{
      //justify-content: center !important;
  }
  &-body{
    padding: .5rem;
  }
  &-notes{
    font-size: 14px;
    text-align: right;
    padding-top:8px ;
    color: #585554CC;
  }
&-buttons{
  width: 143px !important;
  height: 50px !important;

}

  &-img{
      margin-bottom: 24px;
  }
  &-title{
      font-size: 24px;
      font-weight: 500;
      line-height: 12px;
      color: #373534;
      margin-bottom: 16px;
  }
  &-desc{
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #585554;
  }
}



@include media-breakpoint-up(lg) {
  .page {
    &__content {
      margin-top: 100px;
    }
  }

  .modal{
    &-footer{
       padding: 40px;
    }
    &-body{
      padding: 2rem;
  }
}
}
